<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2019 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Aerospace Corporation</li>
              <li>BAE Systems</li>
              <li>
                BATF / National Center for Explosives Training &amp; Research
              </li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>Department of Homeland Security</li>
              <li>Department of Justice</li>
              <li>Esterline Defense Technologies</li>
              <li>Energetic Materials Research and Testing Center</li>
              <li>Federal Bureau of Investigation (FBI)</li>
              <li>
                Federal Institute for Materials Research and Testing (BAM)
              </li>
              <li>Lawrence Livermore National Laboratory</li>
              <li>Los Alamos National Laboratory</li>
              <li>Naval Research Laboratory</li>
              <li>Naval Surface Warfare Center</li>
              <li>
                Orbital ATK
                <ul class="list-disc p-light ml-8">
                  <li>ABL</li>
                  <li>Corporate</li>
                  <li>Bacchus</li>
                  <li>Promontory</li>
                  <li>Lake City</li>
                </ul>
              </li>
              <li>Safety Management Services, Inc.</li>
              <li>Tooele Army Depot</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (DG
                Advisor, LLC)
              </li>
              <li>Sandia National Laboratory</li>
              <li>Signature Science, LLC</li>
              <li>U.S. Army RDECOM – ARDEC – Picatinny Arsenal</li>
              <li>Vista Outdoor – CCI/Speer</li>
              <li>Vista Outdoor – Federal Premium Ammunition</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2019 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F01%20-%20Welcome%20-%20Bob%20Ford.pdf?alt=media&token=a2fb31e4-806a-4262-940c-c330590011ce"
                        >001 – Welcome: Bob Ford – SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F02%20-%20Standardization%20and%20the%20ET%20Users%20Group%20Charter%20-%20Bob%20Ford.pdf?alt=media&token=96257f33-9426-456f-8d49-96cf6f099cca"
                        >002 – Standardization and the ET Users Group Charter:
                        Bob Ford, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F03%20-%20%20Verification%20of%20Standardization%20Overview%20and%20Status%20-%20Clint%20Guymon.pdf?alt=media&token=4c96fa31-4a46-407c-b2f9-282a3ba71ba6"
                        >003 – Verification of Standardization Overview and
                        Status – Clint Guymon, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F04%20-%20Review%20of%20SBAT%20Round%20Robin%20Objectives%20and%20Approach%20-%20Clint%20Guymon.pdf?alt=media&token=bc062a96-99cb-403e-afbb-4f1c60904c3f"
                        >004 – Review of SBAT Round Robin Objectives and
                        Approach – Clint Guymon, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F06%20-%20SMS%20SBAT%20Laboratory%20Test%20Results%20-%20Clint%20Guymon.pdf?alt=media&token=5bada03a-1707-48b9-9a34-fa9873d3061a"
                        >006 – Initial Individual Laboratory Results – Safety
                        Management Services – Clint Guymon</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F07%20-%20SAND2019-11814PE%20-%20ETUG%20SBAT%20Round%20Robin%20Results%20-%20Jason%20Phillips.pdf?alt=media&token=1350bde2-11fd-4c2d-b2db-7348f32bc272"
                        >007 – Initial Individual Laboratory Results – Sandia –
                        Jason Phillips</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F11%20-%20ETUG%20SBAT%20Summary%20of%20Results%20-%20Clint%20Guymon.pdf?alt=media&token=e2e07e2d-0e84-40ed-9e6a-e980b16091bd"
                        >011 – ETUG SBAT Summary of Results – Clint Guymon,
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F12b%20-%20Explosive%20Critical%20Temperature%20-%20Geoff%20Brown.pdf?alt=media&token=58e3cfba-3644-4575-aa4e-9fee433bcc28"
                        >012 – Explosive Critical Temperature – Geoff Brown,
                        LANL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F13%20-%20SBAT%20Extension%20to%20Critical%20Temperature%20-%20Clint%20Guymon.pdf?alt=media&token=2b03d4b9-d4fb-4b5f-8aa8-2a90dc83587a"
                        >013 – SBAT Extension to Critical Temperature – PETN &
                        RDX – Clint Guymon, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F16%20-%20Synergy%20between%20IPC%20and%20Revised%20GHS%202.1%20-%20Bob%20Ford.pdf?alt=media&token=f8ced29d-03d3-4372-865f-ce4ddeea2dcf"
                        >016 – Synergy between In-Process Classification and the
                        GHS 2.1 Revision – Bob Ford, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F17%20-%20CanmetCERL%20MBP%20Test%20-%20Chris%20Badeen.pdf?alt=media&token=181c34d6-4069-4f31-a760-63ffc3e2f294"
                        >017 – CanmetCERL MBP Test: UN 8(e) – Chris Badeen,
                        CERL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F18%20-%20In-Process%20Hazard%20Analysis%2C%20Propellant%20Loading%20Case%20Study%20-%20Glenanne%20Johnson.pdf?alt=media&token=d58ab18d-af8b-4949-9b19-a8a30f36d1a9"
                        >018 – In-Process Hazard Analysis, Propellant Loading
                        Case Study – Glenanne Johnson, EMRTC</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F19%20-%20Focus%20on%20IP%201.3%20-%20Mulwala%20Example%20-%20Troy%20Gardner.pdf?alt=media&token=cf4c6848-7552-4d25-85b9-8eef1576d675"
                        >019 – Focus on IP 1.3: Mulwala Example – Troy Gardner,
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F20%20-%20Varying%20Hazards%20of%201.3%20Substances-Articles%20-%20Troy%20Gardner.pdf?alt=media&token=d945655e-8338-418a-81da-9dbc26753b93"
                        >020 – Varying Hazards of 1.3 Substance/Action – Bob
                        Ford/Troy Gardner, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F21%20-%20ESD%20Reaction%20Detection%20via%20Light%20Analysis%20-%20Revisited%20-%20Benjamin%20Ostrow.pdf?alt=media&token=48c0f483-0cce-4cb5-b7b0-4d6ae60fb625"
                        >021 – ESD Reaction Detection via Light Analysis –
                        Revisited – Benjamin Ostrow, Signature Science</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F22%20-%20IR%20and%20Acoustic%20Detection%20for%20Small-Scale%20Safety%20Testing%20-%20Joshua%20Stroker.pdf?alt=media&token=645db5b4-31c5-46c0-831a-63dc09ecc659"
                        >022 – IR and Acoustic Detection for Small-Scale Safety
                        Testing – Joshua Stoker, Signature Science</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F24%20-%20Updates%20to%20Allied%20Ordnance%20Publication%207%20(AOP-7)%20%E2%80%93%20Erik%20Wrobel.pdf?alt=media&token=d165bba3-fa40-4742-9faf-8d750aee7ba9"
                        >024 – Updates to Allied Ordnance Publication 7 (AOP-7)
                        – Erik Wrobel</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F25%20-%20History%20of%20Class%201%20Explosive%20Test%20Series%201%20%26%202%20Tests%20-%20Boyd%20Hill.pdf?alt=media&token=a39fa8db-d063-45ef-a61f-e35dd12b6cb3"
                        >025 – History of Class 1 Explosive Test Series 1 & 2
                        Tests – Boyd Hill, NGC</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F26%20-%20Special%20Permit%208451%20Key%20Parameters%20and%20Update%20-%20Troy%20Gardner.pdf?alt=media&token=d97169fb-6c37-4b11-8234-e3ef8252fa15"
                        >026 – Special Permit 8451 Key Parameters and Update –
                        Troy Gardner, SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F27%20-%20New%20Range%20Safety%20Standard%20-%20Larry%20Lyon.pdf?alt=media&token=24e32526-4b81-4f9a-8959-ff4dfc1eee5c"
                        >027 – New Range Safety Standard (HOPS) – Larry Lyon,
                        NGC</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href=""
                        >028 – Black Powder Equivalency Test – Kirt Sasser,
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F28%20-%20Black%20Powder%20Equivalency%20Test%20-%20Kirt%20Sasser.pdf?alt=media&token=a5782384-988b-41e8-8bce-5cc1683c0c2e"
                        >029 – Combustible (and Energetic) Dusts – Ryan Dittmar,
                        SMS</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2019%2F30%20-%20Lionel%20AUFAUVRE%20-%20ETusers%20group.pdf?alt=media&token=2c4cc195-e66b-44cc-ad83-2ce199a2f197"
                        >030 – UN Scheme for Class 1: History and Evolutions –
                        Lionel Aufauvre, INERIS</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
